<template>
	<div class="choice">
		<div v-if="allCaughtUp" class="coverup">
			<div class="allCaughtUp">
				All caught up.<br /><br />
				Well done!<br /><br />
				<div v-if="nextResult.id">
					Your score will be updated with your next result at
					{{ nextResult.resultTime | short_date }} for:
					<choice-row v-if="allCaughtUp" :choice="nextResult"></choice-row>
				</div>
				<button class="btn" @click="goToList()">Go to Choice List</button><br /><br />
			</div>
		</div>

		<newbie-overlay
			v-if="user.status == 'newbie' && !allCaughtUp"
			:choice="choice"
		></newbie-overlay>

		<div id="top-title" class="title">{{ choice.name || "Loading choice..." }}</div>
		<div class="cutoff right">{{ choice.cutoffTime | short_date }}</div>

		<div class="visualChoice" v-if="!allCaughtUp">
			<!-- LEFT OPTION -->
			<div
				@click="goToActor(choice.leftOption.actor)"
				class="choiceOption"
				:class="{
					chosen:
						(choice.settled == 0 && confidence < 0) ||
						(choice.settled == 1 &&
							choice.winningOptionID == choice.leftOptionID),
				}"
			>
				<div v-if="!choice.leftOption.actor.imageURL" class="noImage">
					{{ choice.leftOption.actor.name }}
				</div>

				<div class="fullSize" v-if="choice.leftOption.actor.imageURL">
					<img class="fullImage" :src="choice.leftOption.actor.imageURL" />
				</div>
				<div
					class="fullSize"
					v-if="
						choice.settled == 1 &&
						choice.expectation.optionID == choice.leftOptionID
					"
				>
					<img
						v-if="choice.winningOptionID == choice.expectation.optionID"
						src="/img/checkmark.png"
						class="fullImage"
					/>
					<img
						v-if="choice.winningOptionID != choice.expectation.optionID"
						src="/img/redX.png"
						class="fullImage"
					/>
				</div>
				<div v-if="choice.leftOption.outcomeValue" class="leftOutcomeValue">
					{{ choice.leftOption.outcomeValue }}
				</div>

				<div class="leftQualifier" v-if="choice.leftOption.outcome == 'fall'">
					<img class="fullHeightImage" src="/img/minus-arrow.png" />
				</div>
			</div>

			<!-- RIGHT OPTION -->
			<div
				@click="goToActor(choice.rightOption.actor)"
				class="choiceOption"
				:class="{
					chosen:
						(choice.settled == 0 && confidence > 0) ||
						(choice.settled == 1 &&
							choice.winningOptionID == choice.rightOptionID),
				}"
			>
				<div v-if="!choice.rightOption.actor.imageURL" class="noImage">
					{{ choice.rightOption.actor.name }}
				</div>
				<div class="fullSize" v-if="choice.rightOption.actor.imageURL">
					<img class="fullImage" :src="choice.rightOption.actor.imageURL" />
				</div>
				<div
					class="fullSize"
					v-if="
						choice.settled == 1 &&
						choice.expectation.optionID == choice.rightOptionID
					"
				>
					<img
						v-if="choice.winningOptionID == choice.expectation.optionID"
						src="/img/checkmark.png"
						class="fullImage"
					/>
					<img
						v-if="choice.winningOptionID != choice.expectation.optionID"
						src="/img/redX.png"
						class="fullImage"
					/>
				</div>

				<div v-if="choice.rightOption.outcomeValue" class="rightOutcomeValue">
					{{ choice.rightOption.outcomeValue }}
				</div>

				<div v-if="choice.rightOption.outcome == 'rise'" class="rightQualifier">
					<img class="fullHeightImage" src="/img/plus-arrow.png" />
				</div>
			</div>
		</div>

		<!-- Results -->
		<div v-if="choice.settled != 0" class="choiceResult">
			{{ choice.result }}
		</div>
		<input
			v-if="mode == 'admin'"
			type="text"
			class="resultInput"
			v-model="result"
			placeholder="And the winner is..."
		/>
		<div v-if="mode == 'admin'" class="addResult">
			No Result:
			<input type="checkbox" class="toggle" v-model="noResult" />
		</div>

		<!-- CONFIDENCE SLIDER -->
		<slider
			type="confidence"
			color="confidence"
			:locked="mode == 'hindsight'"
			:value="confidence"
			@updated="updateConfidence"
		/>
		<!-- INFLUENCERS -->
		<div v-if="mode != 'admin'" class="sectionHeader">
			Your Influencers
			<div class="plusAdd right" @click="showInfluencerModal()">+</div>
		</div>
		<table class="infInputTable">
			<tr
				v-if="
					!(
						!influencersLoaded ||
						influencers.length > 0 ||
						mode == 'admin' ||
						mode == 'hindsight'
					)
				"
				@click="showInfluencerModal()"
			>
				<td class="addInfluencerInfo">
					A nugget of wisdom? Pet theory? Secret formula? Knowledgable source?
					Tap to add an influencer to your choice...
				</td>
			</tr>
			<tr
				v-for="influencer in influencers"
				v-bind:key="'influencer:' + influencer.name"
				class="infRow"
			>
				<td class="infName txtGood">{{ influencer.name }}</td>
				<td>
					<div
						v-if="influencer.numChoices != 0"
						class="infScore right"
						:class="{ bad: influencer.score < 0, good: influencer.score >= 0 }"
					>
						{{ influencer.score || 0 }} ({{ influencer.numCorrect || 0 }}/{{
							influencer.numChoices || 0
						}})
					</div>
				</td>
				<td width="40%">
					<!-- Influencer slider -->
					<slider
						type="influencer"
						:color="
							influencer.score < 0
								? 'bad'
								: influencer.score > 0
								? 'good'
								: 'neutral'
						"
						:value="influencer.weight"
						:locked="mode == 'hindsight'"
						@updated="updateInfluencerValue(influencer, ...arguments)"
					/>
				</td>
			</tr>
		</table>

		<!-- Add Influencer Modal -->
		<div v-show="influencerModal" class="modalBack">
			<div class="modalBox">
				<div class="modalTitle">Add New Influencer</div>
				<div class="modalDesc">Who or what is influencing your choice?<br /><br />
					Influencers (not specific to {{ choice.name }}) can be added and tracked for all your choices in this feed.<br /><br />
					To keep track of a new influencer, add a short name for it here and you'll be able to analyze it's value across multiple choices.  </div>
				<input
					id="influencer-input"
					class="modalInput"
					type="text"
					v-model="newInfluencer"
					placeholder="The difference maker is..."
				/>
				<div class="modalButton" @click="cancelAddInfluencer">CANCEL</div>
				<div class="modalButton right" @click="addInfluencer">CONTINUE</div><br /><br />
				<div style="font-style: italic;font-size: smaller">
				* If you want to keep track of information for just {{ choice.name }}, choose CANCEL and make a note below instead.
			</div>
			</div>
		</div>

		<!-- NOTES -->
		<div class="sectionHeader">
			Notes
			<input
				ref="newNote"
				v-model="note"
				type="text"
				class="inputNotes"
				placeholder="I think..."
				@click="focusNote()"
			/>
		</div>

		<!-- PRIOR NOTES-->
		<div v-if="mode != 'admin'" class="previousNotes">
			<div
				v-for="priorNote in choice.expectation.notes"
				v-bind:key="'note:' + priorNote.id"
				class="showNote"
				:class="{
					before:
						$services.hasKey('Method') && priorNote.createdAt < choice.cutoffTime,
					during:
						$services.hasKey('Method') &&
						priorNote.createdAt > choice.cutoffTime &&
						priorNote.createdAt < choice.resultTime,
					after:
						$services.hasKey('Method') && priorNote.createdAt > choice.resultTime,
				}"
			>
				<b>{{ priorNote.createdAt | tiny_date }}</b>
				- {{ priorNote.note }}
			</div>
		</div>

		<!-- ACTION BUTTONS -->
		<div class="footer" v-if="!allCaughtUp">
			<button
				v-if="mode == 'hindsight'"
				:disabled="!note"
				class="goButton"
				@click="makeChoice()"
			>
				Save Note
			</button>
			<button
				v-if="user.status != 'newbie' && mode == 'choose'"
				:disabled="confidence == 0"
				class="goButton"
				@click="makeChoice()"
			>
				Lock it in!
			</button>
			<button
				v-if="mode == 'admin'"
				class="goButton admin"
				@click="saveResults()"
			>
				Save Results
			</button>
		</div>
		<div id="toast">Saved</div>
		<div class="footer-spacer" />
		<div class="bottom-spacer" />
	</div>
</template>

<script>
import Choice from "@/models/choice.model";
import ChoiceRow from "@/components/ChoiceRow.vue";
import NewbieOverlay from "@/components/NewbieOverlay.vue";
import Slider from "@/components/Slider.vue";
import moment from 'moment';

export default {
	name: "Choice",
	components: {
		ChoiceRow,
		NewbieOverlay,
		Slider
	},
	data() {
		return {
			allCaughtUp: false,
			confidence: 0,
			id: "next",
			mode: "choose",
			choice: new Choice(),
			influencers: [],
			influencersLoaded: false,
			influencerModal: false,
			newInfluencer: "",
			noteModal: false,
			note: "",
			noResult: false,
			result: "",
			nextResult: new Choice(),
			user: this.$services.me
		};
	},
	watch: {
		$route: function () {
			this.getNextChoice();
		}
	},
	mounted() {
		this.getNextChoice();
	},
	methods: {
		getNextChoice: async function () {
			this.$services.getMe().then(async (me) => {
				this.user = me;
			});
			this.mode = "choose";
			this.id = this.$route.params.id;
			if (!this.id || this.id == "next") {
				this.choice = await this.$services.get("choices/next");
			} else {
				this.choice = await this.$services.get(`choices/${this.id}`);
			}
			if (this.choice) {
				if (moment(this.choice.cutoffTime) < moment()) {
					if (moment(this.choice.resultTime) < moment() && this.user.role == "admin" && this.choice.result == null) {
						// Choice is past result time, no result yet == admin mode
						this.mode = "admin";
					} else {
						this.mode = "hindsight";
					}
				}
				// console.log("Mode is", this.mode)
				if (this.mode != "admin") {
					if (this.choice.expectation) {
						this.confidence = this.choice.expectation.confidence * (this.choice.expectation.optionID == this.choice.leftOptionID ? -1 : 1);
					}
					this.getInfluencers();
				}
			} else {
				this.choice = new Choice();
				if (this.user.status == "newbie") {  // And check for no groups or questions.
					this.$router.push("/no-choices");
				} else {
					const newKey = "Clean Slate";
					if (!this.$services.hasKey(newKey)) {
						await this.$services.addKey(newKey);
						this.user.keys.push(newKey)
						this.$router.push(`/key-info/${newKey}?isNew=true`);
					} else {
						this.nextResult = await this.$services.get(`choices/nextResult`);
						this.allCaughtUp = true;
					}
				}
			}
		},

		focusNote() {
			this.$refs.newNote.focus();
			this.$refs.newNote.scrollIntoView();
		},

		updateConfidence(val) {
			this.confidence = val;
		},

		updateInfluencerValue(inf, val) {
			inf.weight = val;
		},

		getInfluencers: async function () { //Influencer scores are live data
			this.influencers = await this.$services.get(`choices/${this.choice.id}/influencers`)
			this.influencersLoaded = true;
		},

		makeChoice: async function () {
			if (this.confidence != 0) {
				this.choice.expectation.optionID = this.confidence > 0 ? this.choice.rightOptionID : this.choice.leftOptionID;
				this.choice.expectation.confidence = Math.abs(this.confidence);
				this.choice.expectation.note = this.note;
				this.choice.expectation.influencers = this.influencers;
				this.$services.makeChoice(this.choice.expectation).then((result) => {
					this.choice.expectation.notes = result.notes;
					this.note = "";
					this.showToast();
					if (this.$route.params.id == "next") {
						this.getNextChoice();
						document.getElementById('top-title').scrollIntoView();
					}
				});
			}
		},

		saveResults: async function () {
			// console.log("Saving results...");
			if (this.confidence != 0 || this.noResult) {
				this.choice.winningOptionID = this.confidence > 0 ? this.choice.rightOptionID : this.choice.leftOptionID;
				this.choice.result = this.result;
				this.choice.notes = this.note;
				this.choice.settled = this.noResult ? -1 : 1;
				this.$services.updateOne("choices", this.choice.id, this.choice).then(() => {
					this.showToast();
					this.$router.back();
				});
			}
		},

		// Influencer Modal
		showInfluencerModal: async function () {
			this.influencerModal = true;
		},

		cancelAddInfluencer: async function () {
			this.influencerModal = false;
			this.newInfluencer = "";
		},
		addInfluencer: async function () {
			this.newInfluencer = this.newInfluencer.trim();
			if (this.newInfluencer.length > 0) {
				const newOne = {
					id: 0,
					name: this.newInfluencer,
					weight: 0,
					score: 0,
					numChoices: 0,
					numCorrect: 0,
					percentage: 0
				}
				this.influencers.push(newOne);
				this.influencerModal = false;
				this.newInfluencer = "";
			}
		},
		addKey: async function (name) {
			const key = await this.$services.addKey(name);
			if (key.newKey) {
				this.$router.push(`/key-info/${name}?new=true`);
			}
		},
		showToast() {
			const toast = document.getElementById("toast");
			toast.className = "show";
			// After 2 seconds, remove the show class from DIV
			setTimeout(() => {toast.className = toast.className.replace("show", "");}, 1500);
		},
		goToActor(actor) {
			if (this.$services.hasKey("Sherlock")) {
				this.$router.push(`/actor/${actor.id}`)
			}
		},
		goToList() {
			this.$router.push(`/choice-list`)
		}
	}
}

</script>

<style scoped>
.choice {
	overflow-x: visible;
	max-width: 800px;
	height: 85%;
}
.allCaughtUp {
	font-size: 26px;
	text-align: center;
	padding: 10px;
}
.modalBack {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 5;
	background-color: rgba(0, 0, 0, 0.6);
	color: white;
	padding-right: 15px;
}
.modalBox {
	width: 75%;
	margin: 45px 6% 0% 6%;
	background-color: white;
	border-radius: 2px;
	color: black;
	padding: 20px;
	touch-action: manipulation;
}
.modalTitle {
	font-weight: bold;
	font-size: 22px;
	margin-bottom: 10px;
}
.modalDesc {
	font-size: 16px;
	margin-bottom: 10px;
}
.modalInput {
	width: 100%;
	display: block;
	font-size: 16px;
	padding: 5px;
	margin: 30px 0px 30px 0px;
	border-top: none;
	border-left: none;
	border-right: none;
	border-bottom: 2px solid blue;
}
.modalText {
	width: 100%;
	display: block;
	font-size: 14px;
	padding: 3px;
	margin: 10px 0px 10px 0px;
	border-top: none;
	border-left: none;
	border-right: none;
	border-bottom: 2px solid blue;
	touch-action: manipulation;
}
.modalButton {
	display: inline-block;
	color: blue;
}
.header {
	font-size: 28px;
	margin: 5px;
}
.cutoff {
	display: inline-block;
	font-size: 18px;
	padding: 6px 3px 0px 0px;
}
.nextChoice {
	width: 88vw;
}
.visualChoice {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: stretch;
	align-content: stretch;
	margin-top: 3px;
	width: 100%;
}
.choiceOption {
	border-radius: 15px;
	width: 50%;
	background-color: white;
	margin-left: 1px;
	margin-right: 1px;
	display: grid;
	grid-template-columns: 55px auto 55px;
	grid-template-rows: auto 15px 30px;
	text-align: center;
}
.fullSize {
	grid-area: 1 / 1 / 4 / 4;
}
.fullImage {
	width: 100%;
}
.fullHeightImage {
	height: 100%;
}
.chosen {
	background-color: #d6f5d6;
}
.unchosen {
	background-color: #efeff5;
}
.noImage {
	grid-area: 1 / 1 / 4 / 4;
	font-size: 26px;
	color: black;
	padding-top: 15px;
	min-height: 200px;
}
.leftOutcomeValue {
	color: white;
	background-color: rgba(99, 99, 99, 0.8);
	font-size: 24px;
	border-radius: 0px 7px 0px 15px;
	padding: 2px;
	grid-area: 3 / 1 / 4 / 2;
}
.rightOutcomeValue {
	color: white;
	background-color: rgba(99, 99, 99, 0.8);
	font-size: 24px;
	border-radius: 7px 0px 15px 0px;
	padding: 2px 0px 2px 1px;
	grid-area: 3 / 3 / 4 / 4;
}
.leftQualifier {
	grid-area: 2 / 1 / 4 / 2;
}
.rightQualifier {
	grid-area: 2 / 3 / 4 / 4;
}

.choiceResult {
	text-align: center;
	width: 50%;
	margin: 5px 25% 5px 25%;
	font-size: 24px;
	background-color: rgb(222, 222, 222);
	color: black;
	border-radius: 5px;
}
.resultInput {
	font-size: 26px;
	color: black;
	width: 70%;
	margin: 5px 15% 5px 15%;
}
.addResult {
	font-size: 24px;
	text-align: center;
}
.toggle {
	display: inline-block;
	color: red;
	vertical-align: bottom;
	height: 25px;
	width: 25px;
}
.plusAdd {
	height: 20px;
	margin: -13px 5px 0px 0px;
	display: inline-block;
	font-size: 40px;
	font-weight: 600;
	color: white;
	vertical-align: top;
}
.addInfluencerInfo {
	font-size: 20px;
	padding: 5px;
	color: #004080;
}
.addInfluencer {
	display: inline-block;
	margin: 5px 10px 0px 0px;
	font-size: 26px;
	color: white;
	vertical-align: center;
}
.infInputTable {
	border: 1px solid black;
	margin: 1%;
	width: 98%;
	background-color: white;
}
.infRow {
	height: 24px;
}
.infName {
	padding: 2px;
	color: black;
	vertical-align: top;
}
.infScore {
	font-size: 12px;
	vertical-align: top;
}
.txtGood {
	text-shadow: 0px 0.5px green;
}
.txtBad {
	text-shadow: 0px 0.5px #c83232;
}
.infRange {
	margin: 2px;
	height: 27px;
	width: 45%;
	background-color: rgba(0, 0, 0, 0.1);
	border-radius: 15px;
	padding-left: 12.5px;
	padding-right: 12.5px;
}
.infGood {
	background-color: rgba(0, 153, 51, 0.1);
}
.infBad {
	background-color: rgba(153, 0, 51, 0.1);
}
.newInfluencer {
	font-size: 18px;
	margin: 2px;
	padding: 2px;
	width: 200px;
	border: 1px solid black;
}
.inputNotes {
	font-size: 18px;
	margin: 0 1% 0 1%;
	padding: 2px;
	border: 1px solid black;
	width: 96%;
	color: black;
}
.previousNotes {
	font-size: 14px;
	margin: 5px;
}
.before {
	color: #bbbbbb;
}
.during {
	color: #dddddd;
}
.after {
	color: #ffffff;
}
/* From W3 Schools Toast */
/* The toast - position it at the bottom and in the middle of the screen */
.btn {
	font-size: 18px;
	background-color: black;
	color: #6699ff;
	padding: 5px;
	border: 1px solid #6699ff;
	cursor: pointer;
}
#toast {
	visibility: hidden; /* Hidden by default. Visible on click */
	width: 100%;
	background-color: #333;
	color: #fff;
	text-align: center;
	padding: 16px;
	position: fixed;
	z-index: 11;
	bottom: 0px;
	left: 0px;
	height: 30px;
}

/* Show the toast when clicking on a button */
#toast.show {
	visibility: visible; /* Show the toast */
	/* Add animation: Take 0.5 seconds to fade in and out the toast.
  However, delay the fade out process for 1.5 seconds */
	-webkit-animation: fadein 0.5s, fadeout 0.5s 1.5s;
	animation: fadein 0.5s, fadeout 0.5s 1.5s;
}

/* Animations to fade the toast in and out */
@-webkit-keyframes fadein {
	from {
		bottom: -30;
		opacity: 0;
	}
	to {
		bottom: 0px;
		opacity: 1;
	}
}

@keyframes fadein {
	from {
		bottom: -30;
		opacity: 0;
	}
	to {
		bottom: 0px;
		opacity: 1;
	}
}

@-webkit-keyframes fadeout {
	from {
		bottom: 0px;
		opacity: 1;
	}
	to {
		bottom: -30;
		opacity: 0;
	}
}

@keyframes fadeout {
	from {
		bottom: 0px;
		opacity: 1;
	}
	to {
		bottom: -30;
		opacity: 0;
	}
}
</style>

