<template>
	<div class="coverup newbie">
		<div
			v-if="introSlide<2 && ($services.me.firstName || $services.me.userName)"
			class="header"
		>Hello {{$services.me.firstName || $services.me.userName}}.</div>
		<div v-if="introSlide<2" class="header">Welcome to Clever.</div>
		<div
			v-if="introSlide<2"
			class="introSection"
		>Clever Systems is an adaptive analytics tool designed to uncover hidden biases and strengths in your decision making.</div>
		<div
			v-if="introSlide==1"
			class="introSection"
		>It works by asking you how confident you are about your predictions and what's influencing you.</div>
		<div
			v-if="introSlide==1"
			class="introSection"
		>It then tracks, scores, and analyzes your results for you.</div>
		<div v-if="introSlide==2" class="introSection">
			By understanding WHY you're making good (or bad) predictions over time, you can refine and adapt your insights to take advantage
			of the best predictors of success.
		</div>
		<div v-if="introSlide==2" class="introSection">So let's get started with your first choice:</div>
		<div v-if="introSlide==2 || introSlide==3 || introSlide==4 || introSlide==5" class="header">
			{{choice.name}}
			<div class="cutoff">{{choice.cutoffTime | short_date}}</div>
		</div>
		<div
			v-if="introSlide==3"
			class="introSection"
		>Move the Confidence Meter farther to the right the more confident you are that {{rightText}}.</div>
		<div v-if="introSlide==3" class="introSection">
			If you think {{leftText}}, move the Confidence Meter farther to the left.
			<!-- <ion-range class='confidence demo' min=-100 max=100 [(ngModel)]="confidence"></ion-range> -->

			<slider
				type="confidence"
				color="confidence"
				:locked="false"
				:value="confidence"
				@updated="updateConfidence"
			/>
		</div>
		<div
			v-if="introSlide==4 || introSlide==5"
			class="introSection"
		>You can also add influencers and decide how much weight you give them to track their value and success rates over time.</div>
		<div v-if="introSlide==4 || introSlide==5" class="introSection">
			As your results come in and the scores are tallied, you'll be able to unlock additional features, more in-depth analysis,
			and integration with advanced tools like
			<b>
				<i>AI-Assist</i>
			</b>.
		</div>
		<div v-if="introSlide==5" class="introSection">
			By adding notes, tags and additional data points to your choices, you can unlock even more advanced analytics to uncover
			hidden value in your decision making process.
		</div>
		<div
			v-if="introSlide==5"
			class="introSection"
		>The more choices you make, the more you get to learn about yourself.</div>
		<!-- <div v-if="introSlide==6" class="introSection quote">
			<b>"Those who know are wise. Those who know themselves are enlightened."</b>
			<div class="introSection">
				<div class="right">
					<i>- Laozi (Lao Tzu)</i>
				</div>
			</div>
		</div>-->
		<div class="footer">
			<button
				:disabled="introSlide==3 && confidence==0"
				class="goButton"
				@click="nextSlide()"
			>OK &gt;&gt;</button>
		</div>
	</div>
</template>

<script>
import Slider from "@/components/Slider.vue";
export default {
	name: "NewbieOverlay",
	props: ["choice"],
	components: {
		Slider
	},
	data() {
		return {
			introSlide: 0,
			feed: {},
			leftText: "",
			rightText: "",
			confidence: 0
		};
	},
	watch: {
		choice: function (newVal, oldVal) {
			this.loadData();
		}
	},
	mounted() {
		this.loadData();
	},
	methods: {
		loadData: async function () {
			if (this.choice.feedID > 0) {
				this.feed = await this.$services.getOne("feeds", this.choice.feedID);
				// console.log("======after feed", this.feed)
				this.leftText = await this.formatOption("left");
				this.rightText = await this.formatOption("right");

			} else {
				// console.log("UH OH ... no feed if")
			}
		},
		nextSlide: async function () {
			// console.log("this.choice", this.choice);
			this.introSlide++;
			if (this.introSlide == 6) {
				this.addKey("Eyekeya");
				if (!this.$services.me.keys.includes("Eyekeya")) {
					this.$services.me.keys.push("Eyekeya");
				}
			}
		},
		updateConfidence: async function (val) {
			this.confidence = val;
		},
		formatOption: async function (side) {
			// console.log("Generating text for side:'", side)
			let optionText = this.feed.textFormat;
			const actorName = side == "left" ? this.choice.leftOption.actor.name : this.choice.rightOption.actor.name;
			const outcome = side == "left" ? this.choice.leftOption.outcome : this.choice.rightOption.outcome;
			const outcomeValue = side == "left" ? this.choice.leftOption.outcomeValue : this.choice.rightOption.outcomeValue;
			optionText = optionText.replace("{{actor.name}}", actorName);
			optionText = optionText.replace("{{outcome}}", outcome);
			optionText = optionText.replace("{{outcomeValue}}", outcomeValue);
			// console.log("OPtion text is:", optionText)
			return optionText;
		},

		addKey: async function (name) {
			const res = await this.$services.addKey(name);
			this.$router.push(`/key-info/${name}?isNew=true`);
		}
	}
}

</script>

<style scoped>
.newbie {
	font-size: 26px;
	background-color: rgba(0, 0, 0, 0.88);
	text-align: center;
}
.header {
	font-size: 28px;
	margin: 5px;
}
.introSection {
	margin: 10px;
	font-size: 20px;
}
.cutoff {
	display: block;
	font-size: 18px;
	/* padding: 6px 3px 0px 0px; */
}
.demo {
	position: absolute;
	left: 0px;
}
.quote {
	margin-top: 50vw;
}
</style>
