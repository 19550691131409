import { Actor } from './actor.model';
import { Expectation } from './expectation.model';

export class Option {
	public id = "";
	public actorID = 0;
	public actor: Actor = new Actor();
	public outcome = "";
	public outcomeValue = "";
	public text = "";
	public maxScore = 0;
	public isWinner = 0;
	public result = "";
}

export default class Choice {
	public id = 0;
	public name = "";
	public choiceGroupID = 0;
	public categoryID = 0;
	public feedID = 0;
	public leftOptionID = 0;
	public rightOptionID = 0;
	public leftOption: Option = new Option();
	public rightOption: Option = new Option();
	public expectation: Expectation = new Expectation();
	public cutoffTime: Date = new Date();
	public resultTime: Date = new Date();
	public availableTime: Date = new Date();
	public lockTime: Date = new Date();
	public settled = 0; // 0 = Unsettled, 1 = Settled, -1 = No Result
	public winningOptionID = 0;
	public result = "";
	public notes = "";
}
