import {ExpNote} from './expNote.model';
import {Influencer} from './influencer.model';

export class Expectation {
	public id = 0;
	public userID = 0;
	public choiceID = 0;
	public optionID = 0;
	public confidence = 0;
	public settled = 0; // 0 = Unsettled, 1 = Settled, -1 = No Result
	public isCorrect = false;
	public score = 0;
	public note = ""; //For one to add
	public notes: ExpNote[] = [];
	public influencers: Influencer[] = [];
}
